import { AspectRatio, Group, Image, List, Paper, rem, Stack, Title } from "@mantine/core";
import Avatar from "../../../../assets/images/Avatar4.jpg"
import { ReactComponent as ListIcon } from "../../../../assets/images/ListArrow.svg"
import styles from "./ProfileCard.module.css"
import { IconBriefcase, IconMapPin } from "@tabler/icons-react";
import Badge from "../../../../components/Badge";

export default function ProfileCard() {
    return (
        <Paper withBorder={ true } radius="xl" p={ 20 } className={ styles.container } gap="xl">
            <Stack align={ "center" }>
                <AspectRatio ratio={ 1 } maw={ 400 }>
                    <Image src={ Avatar } radius="lg"/>
                </AspectRatio>

                <Group w="100%" gap="sm">
                    <Title order={ 2 }>Привет, я Макс!</Title>
                    <Title order={ 2 } className={ styles.emoji }>👋</Title>
                </Group>

                <List
                    center
                    size="lg"
                    w="100%"
                    icon={ <ListIcon style={ {width: rem(24), height: rem(24)} }/> }
                >
                    <List.Item>Fullstack разработчик</List.Item>
                    <List.Item>3 курс УрФУ, «Программная инженерия»</List.Item>
                </List>

                <Group w="100%">
                    <Badge>
                        <IconMapPin size={ 20 }/>
                        Екатеринбург
                    </Badge>
                    <Badge>
                        <IconBriefcase size={ 20 }/>
                        СберОбразование AI
                    </Badge>
                </Group>
            </Stack>
        </Paper>
    )
}
