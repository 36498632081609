import {ActionIcon, Button, Group, Image, Menu} from '@mantine/core';
import Logo from "../../assets/images/Catik2.svg"
import {Link, useLocation, useNavigate} from "react-router-dom";
import styles from "./styles.module.css";
import {useResize} from "../../hooks/useResize";
import {IconMenu2} from "@tabler/icons-react"
import {useCallback} from "react";

export function Header() {
    const {isMobileView} = useResize();
    const navigate = useNavigate();

    const location = useLocation()

    let selectedKeys = ""
    if (location.pathname.match(/\/work.*/gm))
        selectedKeys = "Projects"
    else if (location.pathname.match(/\/blog.*/gm))
        selectedKeys = "Blog"
    else
        selectedKeys = "Home"

    const toWorks = useCallback(() => {
        navigate("/works")
    }, [navigate])

    const toBlog = useCallback(() => {
        navigate("/blog")
    }, [navigate])

    const toGithub = useCallback(() => {
        navigate("https://github.com/CatDevelop")
    }, [navigate])


    return (
        <div className={styles.container}>
            <Image
                src={Logo}
                h={50}
                w="auto"
                fit="contain"
                onClick={() => navigate("/")}
            />


            {
                !isMobileView &&
                <>
                    <Group justify="center" align="center" h="100%">
                        <Button
                            radius="md"
                            color="gray"
                            variant={selectedKeys === "Projects" ? "light" : "subtle"}
                            onClick={toWorks}
                        >
                            Проекты
                        </Button>
                        <Link className={styles.navbarLink} to="blog">
                            <Button
                                radius="md"
                                color="gray"
                                variant={selectedKeys === "Blog" ? "light" : "subtle"}
                                onClick={toBlog}
                            >
                                Блог
                            </Button>
                        </Link>
                    </Group>

                    <Group justify="end" align="center" h="100%">
                        <Link className={styles.navbarLink} to="https://github.com/CatDevelop" target="_blank">
                            <Button radius="md" color="gray" variant="subtle">
                                GitHub
                            </Button>
                        </Link>
                    </Group>
                </>
            }

            {
                isMobileView &&
                <Menu shadow="md" width={200}>
                    <Menu.Target>
                        <Group justify="end" align="center" h="100%">
                            <ActionIcon radius="md" color="gray" variant="light">
                                <IconMenu2/>
                            </ActionIcon>
                        </Group>

                    </Menu.Target>

                    <Menu.Dropdown>
                        {/*<Menu.Label>Application</Menu.Label>*/}
                        <Menu.Item onClick={toWorks}>
                            Проекты
                        </Menu.Item>
                        <Menu.Item onClick={toBlog}>
                            Блог
                        </Menu.Item>
                        <Link className={styles.navbarLink} to="https://github.com/CatDevelop" target="_blank">
                            <Menu.Item>
                                GitHub
                            </Menu.Item>
                        </Link>
                    </Menu.Dropdown>
                </Menu>
            }
        </div>
    );
}
