import ProfileCard from "../../components/ProfileCard";
import TimelineCard from "../../components/TimelineCard";
import styles from "./HomePage.module.css";
import { Stack } from "@mantine/core";
import StackCard from "../../components/StackCard";
import LinksCard from "../../components/LinksCard";
import AboutCard from "../../components/AboutCard";
import ExperienceCard from "../../components/ExperienceCard";
import {useResize} from "../../../../hooks/useResize";

export function Index() {
    const {isMobileView} = useResize();
    return (
        <div className={ styles.container }>
            <div className={ styles.profileContainer }>
                <ProfileCard/>
            </div>
            <Stack w="100%" maw={isMobileView ? "100%" : 550} gap="lg">
                <AboutCard/>
                <ExperienceCard/>
                <StackCard/>
                <TimelineCard/>
                <LinksCard/>
            </Stack>
        </div>
    )
}
