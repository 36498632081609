import styles from "./ProjectsPage.module.css";
import { Stack, Title } from "@mantine/core";
import { projectOrder, projects } from "../../data";
import { ProjectPreview } from "../../components/ProjectPreview/ProjectPreview";
import React from "react";

export function ProjectsPage() {
    return (
        <Stack className={ styles.container }>
            <Title order={ 2 } align="center">Мои проекты</Title>

            <Stack gap={ 80 }>
                {
                    projectOrder.map((projectID, index) => {
                        let project = projects[projectID]
                        return <ProjectPreview
                            key={ "projectPreview" + index }
                            id={ project.id }
                            category={ project.category }
                            name={ project.name }
                            description={ project.shortDescription }
                            image={ project.previewImage }
                            tags={ project.tags }
                            gitURL={ project.gitURL }
                            projectURL={ project.projectURL }
                            isInvert={ project.id % 2 === 0 }
                        />
                    })
                }
            </Stack>
        </Stack>
    )
}
