import { AppShell } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { Header } from "../Header";
import styles from "./ThinLayout.module.css"

export function ThinLayout() {
    return (
        <AppShell
            header={ {height: 60} }
        >
            <AppShell.Header pl="sm" withBorder={ false } className={ styles.headerContainer }>
                <Header/>
            </AppShell.Header>
            <AppShell.Main>
                <Outlet/>
            </AppShell.Main>
        </AppShell>
    );
}
