import React from 'react';
import { PostsPage } from "./pages/PostsPage";
import { PostPage } from "./pages/PostPage";

export const blogRoutes = [
    {
        path: 'blog',
        element: <PostsPage/>
    },
    {
        path: 'blog/:postID',
        element: <PostPage/>
    },
]
