import { BrowserRouter } from 'react-router-dom';
import { createTheme, MantineProvider } from '@mantine/core';
import { RootRouter } from './routes/RootRouter';
import { Notifications } from "@mantine/notifications";
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@gfazioli/mantine-marquee/styles.layer.css';
import './App.css';
import './themes/variables.css'
import '@mantine/code-highlight/styles.css';
import { ModalsProvider } from "@mantine/modals";

export default function MyApp() {

    const theme = createTheme({
        fontFamily: 'SFPro, serif',
        headings: {fontFamily: 'MPlus, serif'},
    });

    return (
        <div className="App">
            <MantineProvider defaultColorScheme="dark" theme={ theme }>
                <ModalsProvider>
                    <BrowserRouter>
                        <RootRouter/>
                        <Notifications/>
                    </BrowserRouter>
                </ModalsProvider>
            </MantineProvider>
        </div>
    );
}
