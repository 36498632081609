import { Button, Group, Paper, Stack, Text, Timeline, Title } from "@mantine/core";
import styles from "./TimelineCard.module.css"
import { items } from "./data";
import { openImageModal } from "../../../../utils/openImageModal";
import { useNavigate } from "react-router-dom";

export default function TimelineCard() {
    const navigate = useNavigate();

    return (
        <Paper withBorder={ true } radius="xl" p={ 20 } className={ styles.container } gap="xl">
            <Stack align={ "center" }>
                <Title order={ 3 } w="100%">Основные события</Title>
                <Timeline active={ 100 } bulletSize={ 24 } lineWidth={ 2 }>
                    {
                        items.map((item, index) => (
                            <Timeline.Item
                                bullet={ item.icon }
                                title={ <Title order={5}>{ item.title }</Title> }
                                color="gray"
                                key={ `timelineItem${ index }` }
                            >
                                <Text c="dimmed" lh={1} className={ styles.content }>
                                    { item.content }
                                </Text>
                                <Group align="center" gap={ 5 } mt={ 4 }>
                                    <Text size="xs">{ item.description }</Text>
                                    {
                                        item.certificate &&
                                        <Button
                                            variant="transparent"
                                            onClick={ () => openImageModal("certifiacte1", item.certificate) }>
                                            Сертификат
                                        </Button>
                                    }
                                    {
                                        item.blogLink &&
                                        <Button
                                            color="pink"
                                            variant="transparent"
                                            onClick={ () => navigate(item.blogLink) }
                                        >
                                            Статья в блоге
                                        </Button>
                                    }
                                </Group>
                            </Timeline.Item>
                        ))
                    }
                </Timeline>
            </Stack>
        </Paper>
    )
}
