import { Link, useParams } from "react-router-dom";
import React from "react";
import styles from "./PostPage.module.css";
import { projects } from "../../data";
import { Breadcrumbs, Image, Stack, Text, Title } from "@mantine/core";
import { NotFoundPage } from "../../../errors/pages/NotFoundPage/NotFoundPage";
import ProjectInformationBlock from "../../components/ProjectInformationBlock/ProjectInformationBlock";
import ReactPlayer from "react-player";

export default function WorkPage() {
    const {projectID} = useParams();
    const project = projects.at(parseInt(projectID))


    const breadcrumbs = [
        {title: 'Проекты', href: '/works'},
        {title: project?.name, year: project?.year ?? ""},
    ].map((item, index) => (
        item.href
            ? <Link key={ index } to={ item.href }>{ item.title }</Link>
            : <Text> { item.title } { item.year } </Text>
    ));

    if (!project)
        return <NotFoundPage/>

    return (
        <Stack className={ styles.container }>
            <Breadcrumbs mb={ 50 }>
                { breadcrumbs }
            </Breadcrumbs>
            <Stack className={ styles.postContainer } gap="lg" align="start" justify="center">

                <Title order={ 1 } align="center" mb={ 20 }>{ project?.name }</Title>

                <Stack>
                    <div className={ styles.projectDescription }>
                        {
                            project.description
                        }
                    </div>

                    {
                        project.informationBlocks &&
                        <ProjectInformationBlock informationBlocks={ project.informationBlocks }/>
                    }

                    {
                        project.images &&
                        <Stack>
                            <h3 className={ styles.projectBlockTitle }>Скриншоты</h3>
                            { project.images.map((image, i) => {
                                return <Image width={ 650 } src={ image } className={ styles.projectImage }
                                              radius="lg"/>
                            })
                            }
                        </Stack>
                    }

                    {
                        project.video &&
                        <div>
                            <h3 className={ styles.projectBlockTitle }>Видео-демонстрация</h3>
                            <div className={ styles.projectVideo }>
                                <ReactPlayer
                                    url={ project.video }
                                    width="650"
                                    height="360"
                                    controls
                                />
                            </div>
                        </div>
                    }
                </Stack>
            </Stack>
        </Stack>
    )
}
