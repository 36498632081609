import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import styles from "./PostPage.module.css";
import PostGenerator from "../../components/PostGenerator";
import { posts } from "../../data";
import { Breadcrumbs, Stack, Title, Text } from "@mantine/core";

export const PostPage = () => {
    const {postID} = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    if (typeof posts[postID] === 'undefined' || !posts[postID].hasOwnProperty("article"))
        return <p>Статья не найдена</p>

    const breadcrumbs = [
        {title: 'Блог', href: '/blog'},
        {title: posts[postID].articleTitle},
    ].map((item, index) => (
        item.href
            ? <Link key={ index } to={ item.href }>{ item.title }</Link>
            : <Text> { item.title }</Text>
    ));

    return (
        <Stack className={ styles.container }>
            <Breadcrumbs mb={ 50 }>
                { breadcrumbs }
            </Breadcrumbs>
            <Stack className={ styles.postContainer } gap="lg" align="start" justify="center">

                <Title order={ 1 } align="center" mb={ 20 }>{ posts[postID].articleTitle }</Title>

                <PostGenerator blocks={ posts[postID].article }/>
            </Stack>
        </Stack>
    )
}
