import { useRoutes } from 'react-router-dom';
import { ThinLayout } from "../components/ThinLayout";
import { WidthLayout } from "../components/WidthLayout";
import { homeRoutes } from "../features/home/routes";
import { errorsRoutes } from "../features/errors/routes";
import { projectsRoutes } from "../features/projects/routes";
import { blogRoutes } from "../features/blog/routes";

const routes = [
    {
        element: <WidthLayout/>,
        children: [
            ...homeRoutes,
            ...projectsRoutes,
            ...blogRoutes,
            ...errorsRoutes
        ]
    },
    {
        element: <ThinLayout/>,
        children: []
    },

];

export const RootRouter = () => useRoutes(routes);
