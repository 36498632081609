import { modals } from "@mantine/modals";
import { Image } from "@mantine/core";

export const openImageModal = (id, image) => modals.open({
    modalId: `imageModal${id}`,
    children: (
        <div>
            <Image
                radius="md"
                src={image}
                mah={700}
            />
        </div>
    ),
    padding: 0,
    size: "auto",
    withCloseButton: false,
    overlayProps: {
        backgroundOpacity: 0.55,
        blur: 3,
    }
});
