import { Group } from "@mantine/core";
import styles from "./Badge.module.css"

export default function Badge({children}) {
    return (
        <Group className={ styles.container } justify="center" align="center" h="100%" gap={ 4 }>
            { children }
        </Group>
    )
}
