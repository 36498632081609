import { IconBook2, IconTrophy, IconAward, IconHourglassLow, IconPresentation, IconGift } from "@tabler/icons-react";
import { ThemeIcon } from "@mantine/core";
import Certificate1 from "../../../../assets/images/Certificate1.png"
import Certificate2 from "../../../../assets/images/Certificate2.jpg"
import Certificate3 from "../../../../assets/images/Certificate3.jpg"
import Certificate4 from "../../../../assets/images/Certificate4.jpg"
import Certificate5 from "../../../../assets/images/Certificate5.jpg"
import Certificate6 from "../../../../assets/images/Certificate6.jpg"
import Certificate7 from "../../../../assets/images/Certificate7.png"
import Certificate8 from "../../../../assets/images/Certificate8.png"

export const items = [
    {
        icon: <ThemeIcon color="gray" radius="xl" size={ 18 }><IconHourglassLow/></ThemeIcon>,
        title: '8 место во Всероссийском хакатоне "Цифровой прорыв"',
        content: "Разработали систему интеллектуального помощника для генерации ответов на основе базы знаний часто задаваемых вопросов и административную панелью со статистикой.",
        description: '27.09.2024 - 29.09.2024',
        certificate: Certificate8
    },
    {
        icon: <ThemeIcon color="teal" radius="xl" size={ 24 }><IconAward size={ 20 }/></ThemeIcon>,
        title: '4 место в хакатоне от РосАтома "Атомик Хак 2.0"',
        content: "Разработали систему с использованием LLM нейросети для ответов на вопросы, используя контекст базы знаний и ранее успешно решенных вопросов. Ответом нашей системы на вопрос является не только текст, но и вспомогательные картинки, а также ссылки на нужную документацию с указанием страницы",
        description: '14.06.2024 - 16.06.2024',
        certificate: Certificate2
    },
    {
        icon: <ThemeIcon color="yellow" radius="xl" size={ 24 }><IconTrophy size={ 20 }/></ThemeIcon>,
        title: 'Победа в окружном хакатоне ЦФО "Цифровой прорыв"',
        content: 'Мы разработали сервис для автоматической инспекции текстовых комментариев с возможностью гибкой настройки критериев анализа',
        description: '26.04.2024 - 28.04.2024',
        certificate: Certificate1
    },
    {
        icon: <ThemeIcon color="grape" radius="xl" size={ 24 }><IconPresentation size={ 18 }/></ThemeIcon>,
        title: 'Участие в выставке "Форум будущего"',
        content: 'Поучаствовали в выставке в качестве экспонента с демонстрационным вариантом нашего сервиса "Изучение русского жестового языка"',
        description: '05.12.2023 - 08.12.2023',
        certificate: Certificate3,
        blogLink: "/blog/1"
    },
    {
        icon: <ThemeIcon color="yellow" radius="xl" size={ 24 }><IconGift size={ 18 }/></ThemeIcon>,
        title: 'Победа в конкурсе на получение стипендии Selectel Carrer Wave',
        content: 'Стипендия для талантливых студентов технических ВУЗов за лучшее портфолио проектов',
        description: '20.10.2023',
        certificate: Certificate5
    },
    {
        icon: <ThemeIcon color="yellow" radius="xl" size={ 24 }><IconTrophy size={ 20 }/></ThemeIcon>,
        title: 'Победа в хакатоне от Сбера и УрФУ "Код города 300"',
        content: 'Создали MVP десктопного приложения для распознавания и озвучивания русской жестовой речи, что позволяет слабослышащим удобно общаться в онлайн созвонах и конференциях',
        description: '12.08.2023 - 18.08.2023',
        certificate: Certificate6
    },
    {
        icon: <ThemeIcon color="gray" radius="xl" size={ 18 }><IconBook2/></ThemeIcon>,
        title: 'Прохождение курса "Основы программирования на примере C#. Часть 2" (Ulearn)',
        content: 'Изучение generics, генераторов последовательностей, LINQ, алгоритмов и структур данных',
        description: '15.04.2023 - 16.04.2023'
    },
    {
        icon: <ThemeIcon color="gray" radius="xl" size={ 18 }><IconHourglassLow/></ThemeIcon>,
        title: 'Участие в хакатоне от Сбера и УрФУ "SkyHack"',
        content: 'Разработали прототип конструктора для проведения веселых и эффективных скрам-разминок.',
        description: '15.04.2023 - 16.04.2023',
        certificate: Certificate7
    },
    {
        icon: <ThemeIcon color="gray" radius="xl" size={ 18 }><IconHourglassLow/></ThemeIcon>,
        title: 'Участие в хакатоне от УрФУ "Hackathon’22 Code Runner", кейс от Тинькофф',
        content: "Разработали прототип приложения, с помощью которого сотрудники Тинькофф смогут искать себе коллег по интересам, общаться не только на рабочие темы, заводить и поддерживать новые знакомства.",
        description: '29.10.2022 - 30.10.2022'
    },
    {
        icon: <ThemeIcon color="gray" radius="xl" size={ 18 }><IconBook2/></ThemeIcon>,
        title: 'Прохождение курса "React JS - путь самурая 1.0"',
        content: 'Изучение библиотек React, Redux, Thunk, Axios',
        description: '01.09.2022 - 31.12.2022'
    },
    {
        icon: <ThemeIcon color="gray" radius="xl" size={ 18 }><IconBook2/></ThemeIcon>,
        title: 'Прохождение курса "Основы программирования на примере C#. Часть 1" (Ulearn)',
        content: 'Изучение основ синтаксиса C#, стандартных классов .NET, основ ООП и базовых алгоритмов',
        description: '01.09.2022 - 31.12.2022'
    },
    {
        icon: <ThemeIcon color="gray" radius="xl" size={ 18 }><IconBook2/></ThemeIcon>,
        title: 'Прохождение курса "Основы программирования на примере C#. Часть 1" (Ulearn)',
        content: 'Изучение основ синтаксиса C#, стандартных классов .NET, основ ООП и базовых алгоритмов',
        description: '01.09.2022 - 31.12.2022'
    },
    {
        icon: <ThemeIcon color="gray" radius="xl" size={ 18 }><IconBook2/></ThemeIcon>,
        title: 'Обучение в школе программирования Compass Plus It-school',
        content: '- Программирование на С и С++ для начинающих\n- Программирование на С++\n- Математика на компьютерах\n- Программирование на языке Python',
        description: '01.09.2019 - 30.05.2022',
        certificate: Certificate4
    }
];
