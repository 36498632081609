import { Paper, Stack, Title, Tooltip } from "@mantine/core";
import styles from "./StackCard.module.css"
import { Marquee } from "@gfazioli/mantine-marquee";
import ReactLogo from "../../../../assets/images/ReactLogo.svg"
import JSLogo from "../../../../assets/images/JSlogo.svg"
import PHPLogo from "../../../../assets/images/PHPlogo.svg"
import PythonLogo from "../../../../assets/images/PythonLogo.svg"
import CLogo from "../../../../assets/images/Clogo.svg"
import CSSLogo from "../../../../assets/images/CSSlogo.svg"
import HTMLLogo from "../../../../assets/images/HTMLlogo.svg"
import SQLLogo from "../../../../assets/images/SQLlogo.svg"
import NestLogo from "../../../../assets/images/NestJS.svg"

const Technologies = [
    {id: 0, title: "React", icon: ReactLogo},
    {id: 1, title: "NestJS", icon: NestLogo},
    {id: 2, title: "TypeScript", icon: JSLogo},
    {id: 3, title: "Python", icon: PythonLogo},
    {id: 3, title: "C#", icon: CLogo},
    {id: 4, title: "HTML", icon: HTMLLogo},
    {id: 5, title: "CSS", icon: CSSLogo},
    {id: 6, title: "PostgreSQL", icon: SQLLogo},
    {id: 7, title: "PHP", icon: PHPLogo}
]

function TechnologyCard({card}) {
    return (
        <Tooltip label={ card.title } arrow={ false } color="#1e1e1f" offset={{ mainAxis: 5 }}>
            <div className={ styles.card }>
                <img src={ card.icon } alt={ card.title }/>
            </div>
        </Tooltip>
    )
}

function TechnologiesCards({items}) {
    return (
        <Marquee fadeEdges pauseOnHover duration={ 25 } gap="md" fadeEdgesSize="xs" fadeEdgesColor="#2e2e2e">
            { items.map(item => <TechnologyCard key={ "TechnologyCard" + item.id } card={ item }/>) }
        </Marquee>
    )
}

export default function StackCard() {
    return (
        <Paper withBorder={ true } radius="xl" p={ 20 } className={ styles.container } gap="xl">
            <Stack align={ "center" }>
                <Title order={ 3 } w="100%">Мой стек разработки</Title>
                <TechnologiesCards items={ Technologies }/>
            </Stack>
        </Paper>
    )
}
