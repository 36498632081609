import {AppShell} from '@mantine/core';
import {Outlet} from 'react-router-dom';
import {Header} from "../Header";
import styles from "../ThinLayout/ThinLayout.module.css";

export function WidthLayout() {
    return (
        <AppShell
            header={ {height: 60} }
            footer={{height: 0}}
        >
            <AppShell.Header pl="sm" withBorder={ false } className={ styles.headerContainer }>
                <Header/>
            </AppShell.Header>
            <AppShell.Main>
                <Outlet/>
            </AppShell.Main>
        </AppShell>
    );
}
