import { Paper, Stack, Text, Title } from "@mantine/core";
import styles from "./TimelineCard.module.css"

export default function AboutCard() {
    return (
        <Paper withBorder={ true } radius="xl" p={ 20 } className={ styles.container } gap="xl">
            <Stack align={ "center" }>
                <Title order={ 3 } w="100%">Обо мне</Title>
                <Text>
                    Я продуктовый разработчик, специализирующийся на React.
                    Cейчас обучаюсь на 3 курсе Уральского федерального университета,
                    направление «Программная инженерия».
                </Text>
                <Text>
                    Мой путь в IT начался в школе программирования, где я изучал C++ и Python.
                    На данный момент я активно разрабатываю фронтенд на React и пишу бэкенд с использованием NestJS
                    и Python уже 3-й год. Участвую в хакатонах с командой из университета и постоянно стремлюсь
                    расширять свои знания в IT, включая ML, GameDev и DevOps.
                </Text>
            </Stack>
        </Paper>
    )
}
