import React from 'react';
import { ProjectsPage } from "./pages/ProjectsPage";
import WorkPage from "./pages/WorkPage";

export const projectsRoutes = [
    {
        path: 'works',
        element: <ProjectsPage/>
    },
    {
        path: 'work/:projectID',
        element: <WorkPage/>
    },
]
