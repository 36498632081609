import {Button, Group, Paper, Stack, Text, Title} from "@mantine/core";
import styles from "./ExperienceCard.module.css"
import {items} from "./data";
import {Link} from "react-router-dom";
import {IconExternalLink} from "@tabler/icons-react"

export default function ExperienceCard() {
    return (
        <Paper withBorder={true} radius="xl" p={20} className={styles.container} gap="xl">
            <Stack align={"center"}>
                <Title order={3} w="100%">Опыт работы</Title>
                <Stack gap={30} w="100%">
                    {
                        items.map((item, index) => (
                            <Stack gap={5}>
                                <Link to={item.link} target="_blank">
                                    <Button
                                        radius="md"
                                        variant="filled"
                                        color="#242424"
                                        className={styles.badge}
                                        rightSection={<IconExternalLink size={16}/>}
                                    >
                                        {item.title}
                                    </Button>
                                </Link>

                                <Group gap="sm">
                                    <Text fw={700}>
                                        {item.role}
                                    </Text>
                                    <div className={styles.dilimeter}/>
                                    <Text fw={700}>
                                        {item.time}
                                    </Text>
                                </Group>

                                <Text c="dimmed">
                                    {item.description}
                                </Text>
                            </Stack>
                        ))
                    }
                </Stack>
            </Stack>
        </Paper>
    )
}
