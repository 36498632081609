import styles from "./PostsPage.module.css";
import { Stack, Title } from "@mantine/core";
import PostPreview from "../../components/PostPreview";
import { posts } from "../../data";

export function PostsPage() {
    return (
        <Stack className={ styles.container }>
            <Title order={ 2 } align='center'>Мой блог</Title>

            <Stack>
                {
                    posts.map(post => {
                        return <PostPreview key={ "postCard" + post.id } post={ post }/>
                    })
                }
            </Stack>
        </Stack>
    )
}
