export const items = [
    {
        title: "СберОбразование AI",
        link: "https://ed-industry.ru",
        role: "Fullstack-разработчик",
        time: "25.04.2024 - Текущий момент",
        description: "Занимаюсь разработкой фронтенда прототипов с минимальными затратами и T2M для тестирования различных гипотез.",
    },
    {
        title: "ПИН-КОД",
        link: "https://habr.com/ru/users/pin_code/",
        role: "Fullstack-разработчик",
        time: "01.10.2022 - Текущий момент",
        description: "С университетской командой занимаемся разработкой веб-сервисов, участвуем в хакатонах и выставках.",
    }
]
